<template>
  <div>
    <nav style="    height: 204px;">
      <el-button @click="testButton" type="success">成功按钮</el-button>
    </nav>
    <div>
      <PaInput
        class="PaInput_size"
        v-model="value"
        type="phoneNo"
        :submitType="false"
        :is_required="true"
        :icon="require('@/assets/images/main/icon01.png')"
        successContent="选择商店目前销售商品类型，或者您建站完成之后的计划销售商品类型"
      />
      <PaSelect
        class="PaSelect_size"
        v-model="Selectvalue"
        :is_required="true"
        :submitType="false"
        successContent="选择商店目前销售商品类型，或者您建站完成之后的计划销售商品类型"
        :option="list"
      />
      <PaMCSelect
        class="PaSelect_size"
        v-model="Selectvalue3"
        :is_required="true"
        :submitType="false"
        successContent="选择商店目前销售商品类型，或者您建站完成之后的计划销售商品类型。"
        :option="list"
        :isTop="true"
      />
      <PaUpload class="PaUpload"></PaUpload>
    </div>
    <button @click="goto">-z</button>
  </div>
</template>
<script>
import PaInput from "@/components/basic/PaInput.vue";
import PaSelect from "@/components/basic/PaSelect.vue";
import PaUpload from "@/components/basic/PaUpload.vue";
import PaMCSelect from "@/components/basic/PaMCSelect.vue";
import { showMsg } from "@/assets/js/utils";
export default {
  name: "CreateStore",
  data() {
    return {
      value: { value: "", isError: false },
      Selectvalue: { value: "", isError: false },
      Selectvalue2: { value: "", isError: false },
      Selectvalue3: { value: [], isError: false },
      list: [
        {
          value: "选项1",
          label:
            "黄金糕黄金糕黄金糕黄金糕黄金糕黄金糕黄金糕黄金糕黄金糕黄金糕黄金糕黄金糕黄金糕黄金糕黄金糕黄金糕黄金糕黄金糕黄金糕黄金糕黄金糕黄金糕黄金糕黄金糕黄金糕黄金糕黄金糕黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
    };
  },
  components: { PaInput, PaSelect, PaUpload ,PaMCSelect},
  mounted() {},
  methods: {
    testButton() {
      showMsg("test11", 1, 1);
    },
    goto() {
      this.$router.push({
        name: "main",
      });
    },
  },
};
</script>

<style>
.PaInput_size {
  max-width: 570px;
  margin: 10px auto;
}
.PaSelect_size {
  max-width: 270px;
  margin: 10px 20px;
}
.PaUpload{
  margin: 10px auto 0;
}
</style>
